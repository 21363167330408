// API Base URL
export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

// CDN BASE URL
export const CDN_BASE_URL = process.env.REACT_APP_CDN_BASE_URL;

// SOCKET API URL
export const SOCKET_API_URL = process.env.REACT_APP_SOCKET_API_URL;

// App Name
export const APP_NAME = process.env.REACT_APP_APP_NAME;

// 360 Dialog Partnet ID
export const DIALOG360_PARTNER_ID = process.env.REACT_APP_DIALOG360_PARTNER_ID;

// Image Placeholders
export const PLACEHOLDERS = {
  thumbnail: "/media/avatars/blank.png",
};

export const I18N_CONFIG_KEY = process.env.REACT_APP_I18N_CONFIG_KEY || "i18nConfig";

export const PROVIDERS = [
  {
    name: "Twilio",
    code: "twilio",
    services: [
      { name: "Whatsapp Messenger", code: "whatsapp", products: [{ name: "Message", code: "message" }] },
      { name: "Voice Call", code: "call", products: [{ name: "Call", code: "call" }] },
      { name: "Video Call", code: "video", products: [{ name: "Video Call", code: "video" }] },
      { name: "SMS", code: "SMS", products: [{ name: "SMS", code: "SMS" }] },
    ],
  },
  {
    name: "Telnyx",
    code: "telnyx",
    services: [
      { name: "Whatsapp Messenger", code: "whatsapp", products: [{ name: "Message", code: "message" }] },
      { name: "Voice Call", code: "call", products: [{ name: "Call", code: "call" }] },
      { name: "Video Call", code: "video", products: [{ name: "Video Call", code: "video" }] },
      { name: "SMS", code: "SMS", products: [{ name: "SMS", code: "SMS" }] },
    ],
  },
  {
    name: "Whatsapp Web",
    code: "simapp",
    services: [
      {
        name: "Whatsapp",
        code: "whatsapp",
        products: [
          { name: "Profile", code: "profile" },
          { name: "Message", code: "message" },
        ],
      },
    ],
  },
  {
    name: "360 Dialog",
    code: "dialog360",
    services: [
      {
        name: "Whatsapp",
        code: "whatsapp",
        products: [
          { name: "Profile", code: "profile" },
          { name: "Message", code: "message" },
        ],
      },
    ],
  },
  {
    name: "Patiensonboard",
    code: "patientsonboard",
    services: [
      { name: "Gateway", code: "gateway", products: [{ name: "Transaction", code: "transaction" }] },
      { name: "Document", code: "document", products: [{ name: "Conversion", code: "conversion" }] },
      {
        name: "Notification",
        code: "notification",
        products: [
          { name: "Whatsapp", code: "whatsapp" },
          { name: "SMS", code: "sms" },
          { name: "Email", code: "email" },
        ],
      },
    ],
  },
  {
    name: "RedAI",
    code: "simai",
    services: [{ name: "Assistant", code: "assistant", products: [{ name: "Conversation", code: "conversation" }] }],
  },
];

export const PRODUCTS = [];
PROVIDERS?.map(({ name, code, services }) => {
  return services?.map(({ name: serviceName, code: serviceCode, products }) => {
    return products?.map(({ name: productName, code: productCode }) => {
      return PRODUCTS.push({ name: `${name} / ${serviceName} / ${productName}`, code: `${code}.${serviceCode}.${productCode}` });
    });
  });
});
